<template>
  <div>
    <div class="ft20 cl-black cl-main ftw600">活动列表</div>

    <div class="mt20">
      <div class="form-search-box ">
        <a-form layout="inline">
          <a-form-item label="活动名称">
            <a-input v-model="search.name" placeholder="请输入活动名称"></a-input>
          </a-form-item>

          <a-form-model-item label="活动时间" >
            <a-range-picker
                :show-time="{ format: 'HH:mm' }"
                format="YYYY-MM-DD"
                :placeholder="['活动开始时间', '活动结束时间']"
                @ok="onOkAct"
                @change="changeAct"
            />
          </a-form-model-item>

          <a-form-item>
            <a-button @click="searchAct" type="primary">查询</a-button>
            <a-button @click="cancelAct" class="ml10">取消</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>

    <div class="mt20">
      <div class="bg-w">
        <div class="pd30">
          <div style="margin-bottom: 15px;">
            <a-button type="primary" icon="plus" @click="addAct()">添加活动</a-button>
          </div>
          <div class="wxb-table-gray">
            <a-table rowKey="active_id" :columns="columns" :pagination="pagination" :data-source="datas" :loading="loading" @change="handleTableChange">
              <div class="flex alcenter" slot="vip" slot-scope="record,index">
                <img v-if="record.cover_img!=null" :src="record.cover_img" class="index-face" />
                <img v-else src="../../assets/image/woyaoyou_head_defult@2x.png" class="index-face" />
                <div class="ml5" style="text-align: left;">
                  <div>{{record.name}}</div>
                  <a-tag color="green">{{record.status_name.name}}</a-tag>
                </div>
              </div>

              <div class="flex alcenter" slot="contacts" slot-scope="record,index">
                <div class="ml5" style="text-align: left;">
                  <div>{{record.contacts}}</div>
                  <div>{{record.contacts_mobel}}</div>
                </div>
              </div>
              <div slot="status" slot-scope="status,record">
                <a-switch :loading="switchloading"  v-model="record.statusswitch" checked-children="显示"  un-checked-children="隐藏" @change="onClickswitch(record)"  />
              </div>
              <div class="flex alcenter" slot="activetime" slot-scope="record,index">
                <div class="ml5" style="text-align: left;">
                  <div>开始：{{record.start_time}}</div>
                  <div>结束：{{record.end_time}}</div>
                </div>
              </div>

              <div class="flex alcenter left" slot="peoples" slot-scope="record,index">
                <div class="ml5">
                  <div>线上报名：{{record.addcount}}人</div>
                  <div>线上签到：{{record.signcount}}人</div>
                  <div>线下签到：{{record.offline_line}}人</div>
                </div>
              </div>

              <div class="flex alcenter left"  slot="type" slot-scope="type">
                {{ type != null ? type.name : '暂无分类' }}
              </div>

              <template slot="action" slot-scope="record,index">
                <div class="flex center">
                  <a-dropdown placement="bottomRight">
								<span class="more-act">
									<i class="iconfont iconmore_gray"></i>
								</span>
                    <a-menu slot="overlay">
                      <a-menu-item>
                        <a class="menu-act" href="javascript:;" @click="edit(record)">
                          <span>修改信息</span>
                        </a>
                      </a-menu-item>
                      <a-menu-item>
                        <a class="menu-act" href="javascript:;" @click="viewCode(record)">
                          <span>活动码</span>
                        </a>
                      </a-menu-item>
                      <a-menu-item>
                        <a class="menu-act" href="javascript:;" @click="viewFiles(record)">
                          <span>附件管理</span>
                        </a>
                      </a-menu-item>
                      <a-menu-item>
                        <a class="menu-act" href="javascript:;" @click="viewDatas(record)">
                          <span>报名与签到</span>
                        </a>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </template>

            </a-table>
          </div>
        </div>
      </div>
    </div>

    <div v-if="miniqrcodeImg">
      <a-modal v-model="miniqrcodeImg" title="活动码" @cancel="cancelQrcode" :mask="true" width="250px" :footer="null">
        <img  :src="miniqrcode" style="width: 200px;"/>
        <div class="mt16">
          <div class="ft12 ftwblod cl-info text-center">使用微信扫一扫</div>
          <div class="ft12 ftwblod cl-info text-center">保存请使用”右键-另存为“</div>
        </div>
      </a-modal>
    </div>

    <div v-if="jionDataLoing">
      <a-modal v-model="jionDataLoing" title="活动数据" @cancel="cancelQrcode" :mask="true" width="250px" :footer="null">
        <img  :src="miniqrcode" style="width: 200px;"/>
        <div class="mt16">
          <div class="ft12 ftwblod cl-info text-center">使用微信扫一扫</div>
          <div class="ft12 ftwblod cl-info text-center">保存请使用”右键-另存为“</div>
        </div>
      </a-modal>
    </div>

  </div>

</template>

<script>
import {listMixin} from '../../common/mixin/list.js';
export default {
  mixins: [listMixin],
  data() {
    return {
      loading: false,
      editloading:false,
      miniqrcodeImg:false,
      jionDataLoing:false,
      miniqrcode:'',
      confirmLoading:false,
      currentPage: Number(sessionStorage.getItem(localStorage.getItem('client-site-info')+'shopActiveCurrentPage')) || 1,
      pagination: {
        current: Number(sessionStorage.getItem(localStorage.getItem('client-site-info')+'shopActiveCurrentPage')) || 1, // 默认当前页数
        pageSize: 10, //每页中显示10条数据
        total: 0,
        onChange: (current) => {
          this.currentPage = current;
        }
      },
      search: {
        name: '',
        active_time: "",
      },
      level:[],
      columns: [
        {title: '编号',dataIndex: 'active_id',align: 'center',width:100},
        {title: '活动',key: 'name',align: 'left',width:300,scopedSlots: {customRender: 'vip'}},
        {title: '联系人',key: 'contacts',align: 'left',width:180,scopedSlots: {customRender: 'contacts'}},
        {title: '活动时间',key: 'activetime',align: 'left',width:260,scopedSlots: {customRender: 'activetime'}},
        {title: '所属分类', key: 'type', align: 'left', width: 150, scopedSlots: {customRender: 'type'}},
        {title: '报名签到', key: 'peoples', align: 'left', width: 150,scopedSlots: {customRender: 'peoples'}},
        {title: '操作',key: 'action',align: 'center',scopedSlots: {customRender: 'action'}}
      ],
      datas: [],
      member:{},
    }
  },
  methods: {
    getLists(){
      if(this.loading==true) return;
      this.loading=true;
      this.$http.api('admin/getActivityList',{
        name:this.search.name,
        active_time:this.search.active_time,
        limit: this.pagination.pageSize,
        page: this.pagination.current,
      }).then(res=>{
        this.pagination.total=res.total;
        this.pagination.showTotal=(total)=>"共"+res.total+"条";
        this.datas=res.list;
        this.level=res.level;
        this.loading=false;
      }).catch(res=>{
        this.loading=false;
      })
    },
    edit(record){
      sessionStorage.setItem(localStorage.getItem('client-site-info')+'shopActiveCurrentPage', this.currentPage);
      this.$router.push('/activity/editActivity?id='+record.active_id);
    },
    onOkAct(value) {
      console.log('onOkAct: ', value);
    },
    changeAct(date,dateString){
      this.search.active_time = dateString;
    },
    addAct(){
      this.$router.push('/activity/editActivity');
    },
    viewCode(record){
      this.loading=true;
      this.$http.api('admin/codeActivity',{
        id:record.active_id,
      }).then(res=>{
        this.loading=false;
        this.miniqrcodeImg = true;
        this.miniqrcode = res;
      }).catch(res=>{
        this.loading=false;
        this.miniqrcodeImg=false;
      })
    },
    cancelQrcode(){
      this.miniqrcodeImg = false;
    },

    viewFiles(record){
      this.$router.push('/activity/fileActivity?id='+record.active_id+'&name='+record.name);
    },

    viewDatas(record){
      this.$router.push('/activity/joinActivity?id='+record.active_id+'&name='+record.name);
    },
    handleTableChange(pagination, filters, sorter) {
      this.pagination.current = pagination.current;
      sessionStorage.setItem(localStorage.getItem('client-site-info')+'shopActiveCurrentPage', pagination.current);
      this.getLists();
    },
  }
}
</script>

<style>
.index-face {
  width: 70px;
  height: 44px;
  border: 2px solid #FFFFFF;
}

.member-index-action {
  width: 32px;
  height: 32px;
  border-radius: 32px;
  background: #FFFFFF;
  border: 1px solid #EBEDF5;
  text-align: center;
  line-height: 32px;
}
</style>
